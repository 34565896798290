import React, { useEffect } from "react"
import PageRoot from "../components/PageRoot"
import { graphql } from "gatsby"
import ContainerWithPadding from "../components/ContainerWithPadding"
import { getImageUrl, renderCarouselItem, sendViewItemEvent } from "../utils"
import ReadOnlyQuill from "../components/ReadOnlyQuill"
import { Col, Row } from "antd"
import { useTranslation } from "react-i18next"
import AtsGrid from "../components/AtsGrid"
import withOwnershipCheck from "../components/withOwnershipCheck"
import BundleDetailHeader from "../components/BundleDetailHeader"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../constants/imageSizes"

const BundleDetailHeaderWithCheck = withOwnershipCheck(BundleDetailHeader)

const BundleDetailPage = ({ data }) => {
  const { t } = useTranslation()
  const bundle = data.ats.bundles_by_pk
  const { description, landscape, seo, item_rels } = bundle

  useEffect(() => {
    sendViewItemEvent(bundle)
  }, [])

  const seoImage = getImageUrl(landscape, DEFAULT_LANDSCAPE_IMAGE_SIZE)

  return (
    <PageRoot
      title={seo.title}
      description={seo.description}
      image={process.env.GATSBY_AUTH0_REDIRECT_URI + seoImage}
    >
      <div id="intro">
        <BundleDetailHeaderWithCheck data={bundle} />
      </div>
      <ContainerWithPadding align={"start"} size={"large"}>
        <Row gutter={26} className="data-video-container">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={16}
            xl={16}
            xxl={{ span: 14, offset: 2 }}
          >
            <div id="description" className="row-info">
              <h2>{t("label:bundleDescription")}</h2>
              <hr />
              <ReadOnlyQuill value={description} />
            </div>

            <div id="content" className="row-info">
              <h2>{t("label:bundleContent")}</h2>
              <hr />
              <AtsGrid
                colSettings={{
                  xs: 24,
                  sm: 12,
                  md: 8,
                  lg: 8,
                }}
                dataSource={item_rels.map(
                  ({ video, course, certification, gift_card }) =>
                    video || course || certification || gift_card
                )}
                renderItem={renderCarouselItem}
              />
            </div>
          </Col>
        </Row>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export const query = graphql`
  query getBundle($bundleId: ats_uuid!) {
    ats {
      bundles_by_pk(id: $bundleId) {
        ...PreviewCardBundle
        description
        seo {
          title
          description
          slug
        }
        header_image {
          ...SvgImage
        }
        wallpaper {
          ...Wallpaper
        }
        item_rels {
          video {
            ...PreviewCardVideo
          }
          course {
            ...PreviewCardCourse
          }
          certification {
            ...PreviewCardCertification
          }
          gift_card {
            ...PreviewCardGiftCard
          }
        }
      }
    }
  }
`

export default BundleDetailPage
