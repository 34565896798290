import React from "react"
import "./BundleDetailHeader.less"
import { Affix, Anchor, Col, Row, Skeleton, Space } from "antd"
import { Price } from "./Labels"
import { defaultTheme } from "../theme"
import HeaderExtra from "./HeaderExtra"
import PropTypes from "prop-types"
import { FavoriteButton, PurchaseButton } from "./Buttons"
import { useImageColorDetector } from "../hooks/useImageColorDetector"
import { useTranslation } from "react-i18next"
import InstallmentWidget from "./payment/InstallmentWidget"

const BundleDetailHeader = ({
  data,
  loading,
  canPurchase,
  onToggleFavorite,
  onPurchase,
  showFavoriteButton,
  isFavorite,
}) => {
  const {
    title,
    subtitle,
    short_description,
    price,
    price_policy,
    special_price,
    currency,
    header_image,
    wallpaper,
  } = data

  const { isDark } = useImageColorDetector({
    url: wallpaper?.localFile?.childImageSharp.fixed.src,
  })

  const { t } = useTranslation()

  return (
    <div
      className="content-detail-header bundle-detail-header"
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%) , linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%) , url(${
          wallpaper?.localFile?.childImageSharp.fixed.src ||
          defaultTheme.defaultWallpaper
        })`,
      }}
    >
      <div className={`recap-wrapper content ${isDark ? "dark" : "light"}`}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <div className="wrapper-title-image">
              <img src={header_image?.localFile?.publicURL} alt={title} />
            </div>
            <div className="wrapper-title-info">
              {loading ? (
                <Skeleton active />
              ) : (
                <>
                  <Space align={"start"}>
                    {canPurchase && (
                      <PurchaseButton
                        price={price}
                        pricePolicy={price_policy}
                        specialPrice={special_price}
                        onClick={() => {
                          onPurchase && onPurchase(data)
                        }}
                      />
                    )}
                    {showFavoriteButton && (
                      <FavoriteButton
                        onClick={() => {
                          onToggleFavorite && onToggleFavorite(data)
                        }}
                        isFavorite={isFavorite}
                      />
                    )}
                  </Space>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div className="detail-wrapper">
        <Row gutter={60}>
          <Col xs={24} sm={24} md={20} lg={16} xl={16}>
            <h1 className="title">{title}</h1>
            <h2 className="subtitle">{subtitle}</h2>
            <p className="description">{short_description}</p>
            <div>
              <Space direction="horizontal">
                <div className="each-item">
                  <Price
                    price={price}
                    pricePolicy={price_policy}
                    currency={currency}
                  />
                </div>
              </Space>
            </div>
            <HeaderExtra />
          </Col>
          <Col sm={24} xs={24} md={24} lg={8} xl={8} xxl={8}>
            <Affix className="affix-wrapper">
              <div className="side-nav-wrapper">
                <div className="single-action">
                  <Space>
                    {canPurchase && (
                      <>
                        <PurchaseButton
                          price={price}
                          pricePolicy={price_policy}
                          specialPrice={special_price}
                          onClick={() => {
                            onPurchase && onPurchase(data)
                          }}
                        />
                      </>
                    )}
                  </Space>
                  {canPurchase && (
                    <InstallmentWidget
                      onPurchaseClick={() => {
                        onPurchase && onPurchase(data)
                      }}
                      price={price}
                    />
                  )}
                </div>
                <Anchor className="hookers">
                  <Anchor.Link href="#intro" title={t("label:intro")} />
                  <Anchor.Link
                    href="#description"
                    title={t("label:description")}
                  />
                  <Anchor.Link href="#content" title={t("label:content")} />
                </Anchor>
              </div>
            </Affix>
          </Col>
        </Row>
      </div>
    </div>
  )
}

BundleDetailHeader.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  canPurchase: PropTypes.bool,
  canPlay: PropTypes.bool,
  onPurchase: PropTypes.func,
  onPlay: PropTypes.func,
  onToggleFavorite: PropTypes.func,
  isFavorite: PropTypes.bool,
  showFavoriteButton: PropTypes.bool,
  progress: PropTypes.number,
}

export default BundleDetailHeader
